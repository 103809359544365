<script lang="js" setup>
const props = defineProps({
    formData: {
        type: Object,
        required: true
    },
    mewsId: {
        type: String,
        required: true
    },
    mewsIds: {
        type: Array,
        required: false,
        default: () => []
    },
    location: {
        type: Object,
        default: () => {},
        required: false
    },
    roomId: {
        type: String,
        default: '',
        required: false
    },
});

onMounted(() => {
    if (!props.formData.date) {
        return;
    }

    const startYear = new Date(props.formData.date.start).getFullYear();
    const startMonth = new Date(props.formData.date.start).getMonth() + 1;
    const startDay = new Date(props.formData.date.start).getDate();

    const endYear = new Date(props.formData.date.end).getFullYear();
    const endMonth = new Date(props.formData.date.end).getMonth() + 1;
    const endDay = new Date(props.formData.date.end).getDate();
    
    let configurationIds = `[
        '${ props.mewsId }'
    ]`;

    if (props.mewsIds.length) {
        configurationIds = `[
            ${ props.mewsIds.map(id => `'${id}'`).join(', ') }
        ]`;
    }

    useHead({
        script: [
            {
                src: 'https://api.mews.com/distributor/distributor.min.js'
            },
            {
                innerHTML: `Mews.Distributor({
                    configurationIds: ${configurationIds},
                },
                function(api) {
                    ${ props.formData?.date?.start ? `api.setStartDate(new Date(${startYear}, ${ Number(startMonth) - 1}, ${ Number(startDay) }));` : ''}

                    ${ props.formData?.date?.end ? `api.setEndDate(new Date(${endYear}, ${ Number(endMonth) - 1}, ${ Number(endDay)}));` : ''}

                    ${ props.formData?.guests?.adults ? `api.setAdultCount(${props.formData?.guests?.adults});` : '' }
                    ${ props.formData?.guests?.kids ? `api.setChildCount(${props.formData?.guests?.kids});` : '' }

                    ${ props.formData?.code ? `api.setVoucherCode('${props.formData?.code}');` : '' }

                    ${ props.roomId !== '' ? `api.showRates('${props.roomId}');` : 'api.showRooms();'}

                    ${ props.mewsIds?.length ? 'api.showHotels()' : '' }

                    api.open();
                });`
            }
        ]}
    );
});
</script>
